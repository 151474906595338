import useJsonLdFaq from '~/composables/useJsonLdFaq'
import useJsonLdVacationRental from '~/composables/useJsonLdVacationRental'
import useJsonLdItemListOfVacationRentals from '~/composables/useJsonLdItemListOfVacationRentals'

export default defineNuxtPlugin((nuxtApp) => {
  // https://nuxt.com/docs/api/advanced/hooks#app-hooks-runtime
  nuxtApp.hook('app:rendered', () => {
    if (import.meta.client) {
      return
    }

    const faqPage = useJsonLdFaq().getAssembledObject()
    const vacationRental = useJsonLdVacationRental().getAssembledObject()
    const itemListOfVacationRentals = useJsonLdItemListOfVacationRentals().getAssembledObject()
    const aggregateRating = useJsonLdAggregateRating().getAssembledObject()

    if (faqPage === null && vacationRental === null && itemListOfVacationRentals === null) {
      return
    }

    const jsonLd = {
      '@context': 'https://schema.org',
      '@graph': [
        ...(faqPage !== null ? [faqPage] : []),
        ...(vacationRental !== null ? [vacationRental] : []),
        ...(itemListOfVacationRentals !== null ? [itemListOfVacationRentals] : []),
        ...(aggregateRating !== null ? [aggregateRating] : [])
      ]
    }

    useHead(() => {
      return {
        script: [
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(jsonLd, null, 2)
          }
        ]
      }
    })
  })
})
